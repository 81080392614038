.header-section{
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(239,247,255,1) 35%, rgba(242,250,255,1) 100%);
  .nav-bar-margin{
    margin-bottom: 0;
    padding-bottom:0;
    margin-top: 0;
    padding-top:0;
    min-height: 8.125rem  /* 130/16 */;
  }
  .navbar-light .navbar-nav .nav-link {
    color: #00355F;
    font-size: 16px;
    font-weight: 450 !important;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .massy-card-logo{
    width: 8rem;
    margin-left: 20px;
  }
  .log-in {
    outline: none;
    border: none;
    cursor: pointer;
    display: block;
    position: relative;
    background: #f47921;
    font-size: 12px;
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 0.625rem  /* 10/16 */ 1.875rem  /* 30/16 */;
    border-radius: 1.25rem  /* 20/16 */;
  }
  .join-now {
    outline: none;
    border: none;
    cursor: pointer;
    display: block;
    position: relative;
    background: #00355F;
    font-size: 12px;
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 0.625rem  /* 10/16 */ 1.875rem  /* 30/16 */;
    border-radius: 1.25rem  /* 20/16 */;
  }
  .main-image{
    width: 100%;
    min-height: 46rem;
  }
  .customer-portal-background{
    width: 100%;
    position: relative;
  }
  .main-text-container{
    position: absolute;
    z-index: 1;
    top: 13rem;
    left: 5rem;

    .main-text{
      font-size: 4rem  /* 50/16 */;
      text-align: left;
      font-weight: 700 !important;
      color: #00335c;
    }
    .second-main-text{
      font-size: 3.125rem  /* 50/16 */;
      line-height: 1.2;
      text-align: left;
      font-weight: unset;
      color: #ffffff;
    }
    .main-text-two{
      font-size: 1.125rem  /* 18/16 */;
      line-height: 4;
      text-align: left;
      color: #000;
    }
    .terms-conditions-text{
      font-size: 1rem  /* 16/16 */;
      text-align: left;
      color: #000;

    }
  }

  .join-now-main{
    outline: none;
    border: none;
    cursor: pointer;
    margin-top: 20px;
    display: block;
    position: relative;
    background: #00355F;
    font-size: 25px;
    color: #ffffff;
    letter-spacing: 1px;
    padding: 30px 60px;
    border-radius: 100px;
  }

  .nav-dropdown-mobile{
    font-size: 1rem;
  }

  .mobile-header{
    display: flex;
    align-items: center;
    .massy-card-mobile{
      margin-top: 1.25rem;
      width: 10rem;
    }
    .main-text-mobile{
      margin-top: 0.625rem;
      padding-left: 1.875rem;
      padding-right: 1.875rem ;
      font-size: 1.875rem;
      line-height: 1.33;
      font-weight: bold;
      color: #00335c;
      text-align: center;
    }

    .sign-up-mobile{
      margin-top: 1.5rem;
      width: 9.375rem;
      height: 2.5rem;
      background-color: #F47B19;
      box-sizing: border-box;
      appearance: none;
      border: 1px solid  #F47B19;
      border-radius: 0.625rem;
      color: #ffffff;
      font-weight: bold;
      box-shadow: rgba(0, 0, 0, 0.16) 0 10px 36px 0, rgba(0, 0, 0, 0.06) 0 0 0 1px;
    }

  }
  @media (min-width:900px) and (max-width:1300px) {
    .navbar-light .navbar-nav .nav-link {
      font-size: 14px;
      padding-left: 0.5rem;
      padding-right:0.5rem;
    }
    .main-image{
      min-height: 10rem;
    }
    .massy-card-logo{
      width: 5rem;
    }
    .log-in {
      padding: 0.3125rem  0.9375rem;
    }
    .join-now {
      padding: 0.3125rem  0.9375rem;
    }
    .main-text-container{

      .main-text{
        font-size: 3.5rem;
      }
      .second-main-text{
        font-size: 2.5rem;
      }
      .main-text-two{
        font-size: 0.9rem
      }
      .terms-conditions-text{
        font-size: 0.9rem
      }
    }

    .join-now-main{
      margin-top: 1rem;
      padding: 1rem  2rem;

    }
  }
  @media only screen and (max-width: 61.875rem){
    .navbar {
      box-shadow: 2px 4px 8px -1px rgba(133, 133, 133, 0.52);
    }
    .log-in {
      width: 80%;
    }
    .join-now {
      width: 80%;
    }
    .dropdown-toggle {
      font-size:0.75rem  /* 12/16 */;
    }
    .navbar-light .navbar-nav .nav-link {
      color: #00355F;
      display: flex;
      justify-content: flex-start;
      margin-left: 20px;
      border-bottom: 1px solid #e5e5e5;
    }
    .massy-card-logo{
      width: 5rem;
    }
    .nav-bar-margin{
      min-height: 4rem ;
    }
  }
  @media only screen and (max-width: 21.875rem  /* 350/16 */){
    .massy-card-logo{
      width: 2rem;
    }
  }
}
