.header {
  background-color: white;
  min-height: 3.75rem  /* 60/16 */;

  .logo-container {
    min-width: 16rem;
    width: 16rem;

    .logo {
      width: 5.5rem;
    }
  }

  .profile-selector {
    .dropdown-toggle {

      &:active, &:focus {
        box-shadow: none;
      }

      &::after {
        display: none;
      }
    }

    .dropdown-menu {
      .dropdown-item {
        &:active, &:focus, &:hover {
          background-color: transparent;
        }
      }
    }
  }
  @media only screen and (max-width: 600px) {
    min-height: 58px;
    .logo-container {
      min-width: 85px;
      width: 85px;
      margin-left: 16px;
      img{
        width: 85px;
      }
    }
  }
}
