.get-help{
  background-image: url("../../../assets/images/profile-header-background.png");
  background-repeat: no-repeat, repeat;
  background-position:right top;
  .main-image{
    width: 500px;
    height: auto;
  }
  .main-text{
    font-size: 40px;
    color: #26ABE3;
  }
  .contact-name{
    font-size: 18px;
    color: #8692A6;
  }
  .contact-info{
    font-size: 16px;
    color: #26ABE3;
  }
}
