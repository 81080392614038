.wizard-header {
  .icon-container-outer {
    width: 4.875rem;
    height: 4.875rem;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    position: relative;
    border-radius: 50%;

    .icon-container {
      width: 3.875rem;
      height: 3.875rem;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;

      .sh-icon {
        font-size: 2rem;
      }
    }
  }
  .line-container {
    margin-top: 1.9375rem;
    div {
      height: 1px;
    }
  }

  @media only screen and (max-width: 768px) {
    font-size: 0.5rem;
    .icon-container-outer {
      width: 1.69375rem;
      height: 1.69375rem;
      .icon-container {
        width: 1.34625rem;
        height: 1.34625rem;
        .sh-icon {
          font-size: 0.8125rem;
        }
      }
    }
    .line-container {
      margin-top: 0.9375rem;
    }
  }
}
