// @import './../../../dist/css/bootstrap.scss';
.form-date.dropdown {

 
  .dropdown-toggle {
    min-width: 13.125rem;
    text-align: left;
    // border: solid 1px rgba(112, 112, 112, 1);
    background-color: #fff;
    // padding: 0.688rem 0.938rem;
    // font-size: 1rem;
    // line-height: 1.14;
    // height: 2.563rem;
    opacity: 1;
    color: $input-color;
    border-color: $input-border-color;

    &.is-valid{
      border-color: $success;
    }
    &.is-invalid{
      border-color: $danger;
    }
    &:focus {
      background-color: $input-focus-bg;
      border-color: $input-focus-border-color;

      box-shadow: $input-focus-box-shadow;
    }

   
    &::after {
      float: right;
      text-align: right;
      color: #000;
      content: '';
      border: none;
      width: 1em;
      height: 1em;
      margin-top: 0.2em;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg id='calendar_1_' data-name='calendar' xmlns='http://www.w3.org/2000/svg' width='13.953' height='13.953' viewBox='0 0 13.953 13.953'%3e%3cpath id='Path_1615' data-name='Path 1615' d='M12.154,1.09H10.9V.436a.436.436,0,0,0-.872,0V1.09h-6.1V.436a.436.436,0,0,0-.872,0V1.09H1.8A1.8,1.8,0,0,0,0,2.889v9.266a1.8,1.8,0,0,0,1.8,1.8H12.154a1.8,1.8,0,0,0,1.8-1.8V2.889A1.8,1.8,0,0,0,12.154,1.09ZM1.8,1.962H3.052V2.4a.436.436,0,0,0,.872,0V1.962h6.1V2.4a.436.436,0,0,0,.872,0V1.962h1.254a.928.928,0,0,1,.927.927V3.924H.872V2.889A.928.928,0,0,1,1.8,1.962ZM12.154,13.081H1.8a.928.928,0,0,1-.927-.927V4.8H13.081v7.358A.928.928,0,0,1,12.154,13.081Z'/%3e%3c/svg%3e ");
      background-size: 1em 1em;
    }

    // &.form-control-sm{
    //   font-size: 0.875rem;
    // }

    &.placeholder{
      color:rgba(0, 0, 0, 0.5);
    }
    &[disabled] {
      opacity: 0.48;
    }
  }

  &.show,
  &:active {
    .dropdown-toggle {
      &:not(.is-invalid){
      border-color: $input-focus-border-color!important;
      }

      box-shadow: $input-focus-box-shadow!important;
    }
  }

  .rdrCalendarWrapper {
    .rdrSelected,
    .rdrDayStartPreview,
    .rdrDayEndPreview,
    .rdrDayHovered .rdrDayNumber::after {
      border-radius: 5px;
    }
  }
}
