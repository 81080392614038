.sidebar-wrapper {
    background-color: white;
    font-size: 0.875rem  /* 14/16 */;
    min-width: 13rem;
    width: 13rem  /* 230/16 */;
    .side-navigation {
        padding: 0.75rem;

        .nav-link {
            border-radius: 0.25rem;
            // color: var(--bs-primary);
            &.active {
                background-color: var(--bs-primary);
                color: white;
            }
        }

        .help-container {
            padding: 0.5rem 1.5rem;
            .help-banner {
                width: 10rem;
                margin-left: 2rem;

            }
        }
    }
}
