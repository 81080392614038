.error-boundary{
  background-image: url("../src/assets/images/signup-banner.png");
  background-repeat: no-repeat, repeat;
  width: 100%;
  background-color: #ffffff;
  height: 24.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  .connection-lost{
    margin-top: 60px;
    width: 700px;
  }
  .main-text{
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    margin-bottom: 10px;
    line-height: 175%;
    color: #26ABE3;
  }
  .custom-btn {
    width: 8.125rem  /* 130/16 */;
    max-height: 2.5rem  /* 40/16 */;
    color: #fff;
    border-radius: 0.3125rem  /* 5/16 */;
    padding: 0.625rem  /* 10/16 */ 1.5625rem  /* 25/16 */;
    font-weight: 500;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    outline: none;
  }
  .btn-3 {
    background: rgb(0,172,238);
    background: linear-gradient(0deg, rgba(0,172,238,1) 0%, rgba(2,126,251,1) 100%);
    width: 130px;
    min-height: 40px;
    line-height: 42px;
    padding: 0;
    border: none;

  }
  .btn-3 span {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
  }
  .btn-3:before,
  .btn-3:after {
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    background:rgb(0,172,238);
    transition: all 0.3s ease;
  }
  .btn-3:before {
    height: 0;
    width: 2px;
  }
  .btn-3:after {
    width: 0;
    min-height: 2px;
  }
  .btn-3:hover{
    background: transparent;
    box-shadow: none;
  }
  .btn-3:hover:before {
    height: 100%;
  }
  .btn-3:hover:after {
    width: 100%;
  }
  .btn-3 span:hover{
    color: rgb(0,172,238,1);
  }
  .btn-3 span:before,
  .btn-3 span:after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    background:rgb(0,172,238,1);
    transition: all 0.3s ease;
  }
  .btn-3 span:before {
    width: 2px;
    height: 0;
  }
  .btn-3 span:after {
    width: 0;
    min-height: 2px;
  }
  .btn-3 span:hover:before {
    height: 100%;
  }
  .btn-3 span:hover:after {
    width: 100%;
  }
}
