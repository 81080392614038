html,
body,
#root,
.App {
  height: 100vh;
  background: #E9ECEF;
}


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 90%;
}

.heading {
  font-size: 1.5rem;
  line-height: 2.625rem;
  font-weight: 500;
}

.title {
  font-size: 1.125rem;
  line-height: 1.625rem;
  font-weight: 400;
}

.subtitle {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
}

.text-justify{
  text-align: justify;
}
